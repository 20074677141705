import ReactDOM from "react-dom";
import React from "react";
import "./scss/all.scss";
import App from './App';
import * as serviceWorker from './serviceWorker';
window.THREE = require("./vendor/three.min.js");


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
ReactDOM.render(<App />, document.getElementById('root'));
