import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useParams
} from "react-router-dom";

import TopAppBar, {
  TopAppBarFixedAdjust,
  TopAppBarIcon,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle
} from "@material/react-top-app-bar";

import SphereViewer from "./SphereViewer";
import DemoSphere from "./images/preview.jpg";
import LogoUrl from "./images/logo.svg";

const buildSphereUrl = id => `https://s3-us-west-2.amazonaws.com/spheres/${id}`;

const ShowSphere = () => {
  let { id } = useParams();
  return <SphereViewer sphere={buildSphereUrl(id)} />;
};

const Logo = props => <img src={LogoUrl} alt="Spherely Logo" {...props} />;

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/s/:id/embed">
          <React.Fragment>
            <a href="/" class="embed-brand">
              <Logo className="embed-brand__logo" />
              <span className="embed-brand__title">Spherely</span>
            </a>
            <ShowSphere />
          </React.Fragment>
        </Route>
        <Route>
          <TopAppBar>
            <TopAppBarRow>
              <TopAppBarSection align="start">
                <TopAppBarIcon navIcon tabIndex={0}>
                  <Logo />
                </TopAppBarIcon>
                <TopAppBarTitle>Spherely</TopAppBarTitle>
              </TopAppBarSection>
            </TopAppBarRow>
          </TopAppBar>
          <TopAppBarFixedAdjust>
            <Switch>
              <Route path="/s/:id">
                <ShowSphere />
              </Route>
              <Route path="/s/:id/embed"></Route>
              <Route path="/demo">
                <SphereViewer sphere={DemoSphere} />
              </Route>
              <Route path="/">
                <Redirect to="/demo" />
              </Route>
            </Switch>
          </TopAppBarFixedAdjust>
        </Route>
      </Switch>
    </Router>
  );
}
