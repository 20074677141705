import React from "react";
import ReactDOMServer from "react-dom/server.browser";
import PhotoSphereViewer from "./vendor/photo-sphere-viewer";
import Logo from "./images/logo.svg";

const Loading = () => (
  <div className="loading-box">
    <img src={Logo} alt="Loading indicator" className="loading-indicator" />
    <div id="loading-progress" className="progress-bar">
      <span className="meter" style={{ width: "1%" }}></span>
    </div>
  </div>
);

class SphereViewer extends React.Component {
  componentDidMount() {
    this.viewer = new PhotoSphereViewer({
      panorama: this.props.sphere,
      container: this.container,
      navbar: true,
      loading_html: ReactDOMServer.renderToString(<Loading indeterminate />)
    });
  }

  componentWillUnmount() {
    if (this.viewer) this.viewer.destroy();
  }

  render() {
    return (
      <div className="sphere-viewer" ref={c => (this.container = c)}>
        <Loading indeterminate />
      </div>
    );
  }
}

export default SphereViewer;
